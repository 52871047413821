<script>
import Switches from "vue-switches";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      equipments: []
    }
  },
  components: { Switches },
  mounted() {
    this.fetchEquipments()
  },
  methods: {
    fetchEquipments() {
      this.$axios.get(`treatment/${this.$route.params.id}/equipment`)
        .then( response => {
          this.equipments = response.data.result.equipments
        })
    },
    onSubmit() {
      let param = new FormData()
      this.equipments.forEach( eq => {
        if(eq.isPresent === true){
          param.append('equipments', eq.equipmentId)
        }
      })
      
      this.$axios.put(`treatment/${this.$route.params.id}/equipment`, param)
        .then( () => {
          Swal.fire("Success", "Treatment Equipments Updated Successfully", "success");
        })
    }
  }
}
</script>

<template>
  <div id="edit-equipment">
    <div class="table-responsive mb-0">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 5%">No</th>
            <th style="width: 85%">Name</th>
            <th style="width: 10%">Applicable</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(equipment, index) in equipments" :key="index">
            <th scope="row">{{ index+1 }}</th>
            <td>{{ equipment.name }}</td>
            <td>
              <switches v-model="equipment.isPresent" type-bold="false" color="warning" class="ml-1 mb-0"></switches>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" @click="onSubmit" class="btn btn-primary m-1 float-right">Submit Change</button>
    </div>
  </div>
</template>