<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

import {
  required
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      form: {
        name: null,
        duration: null,
        price: null,
        bufferTime: null,
        suitable: null,
        description: null,
        sku: null,
        status: null,
        categories: [],
        tags: [],
        order: 1,
        availability: [],
        image: null,
      },
      imageUrl: null,
      typesubmit: false,
      isError: false,
      errorMessage: null,
      options: [
        { name: 'Active', value: 1 },
        { name: 'Not Active', value: 0 }
      ],
      categories: [],
      isLoadingCategory: false,
      hashtags: [],
      availabilities: ['app','web','booking_system'],
      isLoadingTag: false,
      isCategoriesNotSelected: false,
      isStatusNotSelected: false,
      isAvailabilityNotSelected: false,
    };
  },
  validations: {
    form: {
      name: { required },
      duration: { required },
      price: { required },
      bufferTime: { required },
      description: { required },
      sku: { required },
      order: { required },
    }
  },
  mounted() {
    this.fetchCategory()
    this.fetchTreatment()
    this.fetchTags('');
  },
  methods: {
    onSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {

        this.isCategoriesNotSelected = (this.form.categories.length === 0)? true : false
        this.isStatusNotSelected = (this.form.status === null)? true : false
        this.isAvailabilityNotSelected = (this.form.availability === null)? true : false

        if(this.isStatusNotSelected || this.isCategoriesNotSelected || this.isAvailabilityNotSelected) {
          return false
        }

        // do your submit logic here
        let tags = this.form.tags.map((tag) => {
          return tag.name
        })
        let price = this.convertToAngka(this.form.price)
        console.log(tags)
        let param = new FormData();
        param.append('name', this.form.name) 
        param.append('price', price)
        param.append('duration', this.form.duration)
        param.append('bufferTime', this.form.bufferTime)
        param.append('suitable', this.form.suitable)
        param.append('description', this.form.description) 
        param.append('sku', this.form.sku)
        param.append('tags', tags.join(','))
        param.append('status', this.form.status.value)
        param.append('order', this.form.order)
        param.append('availability', this.form.availability.join(','))
        if (this.form.image) {
          param.append('image', this.form.image)
        }

        for (let index = 0; index < this.form.categories.length; index++) {
          const category = this.form.categories[index];
          param.append('categories', category.categoryId);
        }

        console.log(param)

        this.$axios.post(`treatment/${this.$route.params.id}`, param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Treatment Data Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    fetchCategory() {
      this.isLoadingCategory = true
      this.$axios.get(`category`, {
        params: {
          sortBy: 'name',
          sortType: 1
        }
      }).then( response => {
        let result = response.data.result.category
        this.categories = result
        this.isLoadingCategory = false
      })
    },
    fetchTags(query) {
      this.isLoadingTag = true
      this.$axios.get(`hashtag`, {
        params: {
          search: query,
          sortBy: 'name',
          sortType: 1
        }
      }).then( response => {
        let result = response.data.result.hashtag
        this.hashtags = result
        this.isLoadingTag = false
      })
    },
    addTag(tag) {
      let param = new FormData();
      param.append('name', tag)

      this.$axios.post(`hashtag`, param).then( response => {
        let result = response.data.result.tag
        result.hashtagId = result._id
        this.form.tags.push(result)
      })
    },
    fetchTreatment() {
      this.$axios.get(`treatment/${this.$route.params.id}`)
        .then( response => {
          const result = response.data.result

          let status;
          this.options.forEach( data => {
            if(data.value === result.treatment.status){
              status = data
            }
          })

          this.setTag(result.treatment.tags)
          const categories = result.treatment.categories.map(category => { 
              return {
                ...category, 
                categoryId: category._id
              }
          })
          this.form = {
            name: result.treatment.name,
            duration: result.treatment.duration,
            price: this.convertToRupiah(result.treatment.price, false),
            bufferTime: result.treatment.bufferTime,
            suitable: result.treatment.suitable,
            description: result.treatment.description,
            sku: result.treatment.sku,
            categories: categories,
            status: status,
            tags: [],
            order: result.treatment.order,
            availability: result.treatment.availability,
          }

          this.imageUrl = result.treatment.image;
        })
        .catch( e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    formatRupiah() {
      const angka = this.convertToAngka(this.form.price)
      const rupaiah = this.convertToRupiah(angka, false)
      this.form.price = rupaiah
    },
    setTag(tags) {
      this.$axios.get(`hashtag`, {
        params: {
          sortBy: 'name',
          sortType: 1,
          limit: 1000
        }
      }).then( response => {
        let result = response.data.result.hashtag
        
        result.forEach( item => {
          tags.forEach( tag => {
            if(item.name == tag) {
              console.log('ada')
              console.log(this.form)
              this.form.tags.push(item)
            }
          })
        })
      })
    },
    removeAvailibility(flatform) {
      this.form.availability = this.form.availability.filter(item => item != flatform)
    },
    removeCategory(category) {
      this.form.categories = this.form.categories.filter(item => item.categoryId != category.categoryId)
    },
    upload(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','svg']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.image = event.target.files[0]
          this.errorMessage = null
          this.imageUrl = URL.create(event.target.files[0])
          this.isError = false
        }else{
          this.isError = true
          this.imageUrl = null
          this.errorMessage = "image extentions not allowed"
        }
      }
    }
  }
};
</script>

<template>
  <div id="edit-data">
    <b-alert
      v-model="isError"
      variant="danger"
      class="mt-3"
      dismissible
    >{{errorMessage}}</b-alert>
    <form action="#" @submit.prevent="onSubmit">
      <div class="form-group">
        <label>Name</label>
        <input
          v-model="form.name"
          type="text"
          class="form-control"
          name="name"
          :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
        />
        <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
          <span v-if="!$v.form.name.required">This value is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label>Duration</label>
        <b-input-group append="minutes">
          <input
            v-model="form.duration"
            type="number"
            class="form-control"
            name="duration"
            :class="{ 'is-invalid': typesubmit && $v.form.duration.$error }"
          />
          <div v-if="typesubmit && $v.form.duration.$error" class="invalid-feedback">
            <span v-if="!$v.form.duration.required">This value is required.</span>
          </div>                
        </b-input-group>
      </div>

      <div class="form-group">
        <label>Price</label>
        <b-input-group prepend="Rp.">
          <input
            v-model="form.price"
            type="text"
            class="form-control"
            name="price"
            @keyup="formatRupiah"
            :class="{ 'is-invalid': typesubmit && $v.form.price.$error }"
          />
          <div v-if="typesubmit && $v.form.price.$error" class="invalid-feedback">
            <span v-if="!$v.form.price.required">This value is required.</span>
          </div>
        </b-input-group>
      </div>

      <div class="form-group">
        <label>Buffer Time</label>
        <b-input-group append="minutes">
          <input
            v-model="form.bufferTime"
            type="number"
            name="bufferTime"
            class="form-control"
            :class="{ 'is-invalid': typesubmit && $v.form.bufferTime.$error }"
          />
          <div v-if="typesubmit && $v.form.bufferTime.$error" class="invalid-feedback">
            <span v-if="!$v.form.bufferTime.required">This value is required.</span>
          </div>
        </b-input-group>
      </div>

      <div class="form-group">
        <label>Suitable for</label>
        <input
          v-model="form.suitable"
          type="text"
          class="form-control"
          name="suitable"
        />
      </div>

      <div class="form-group">
        <label>Image</label>
        <input type="file" id="image" ref="image" accept=".jpg, .jpeg, .png, .svg" @change="upload" class="form-control" name="image" />
        <img v-if="imageUrl" :src="imageUrl" class="img-thumbnail" />
      </div>

      <div class="form-group">
        <label>Description</label>
        <div>
          <textarea
            v-model="form.description"
            name="description"
            class="form-control"
            :class="{ 'is-invalid': typesubmit && $v.form.description.$error }"
          ></textarea>
          <div v-if="typesubmit && $v.form.description.$error" class="invalid-feedback">
            <span v-if="!$v.form.description.required">This value is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>SKU</label>
        <input
          v-model="form.sku"
          type="text"
          class="form-control"
          name="sku"
          :class="{ 'is-invalid': typesubmit && $v.form.sku.$error }"
        />
        <div v-if="typesubmit && $v.form.sku.$error" class="invalid-feedback">
          <span v-if="!$v.form.sku.required">This value is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label>Ordering</label>
        <input
          v-model="form.order"
          type="number"
          min="1"
          step="1"
          class="form-control"
          name="order"
          :class="{ 'is-invalid': typesubmit && $v.form.order.$error }"
        />
        <div v-if="typesubmit && $v.form.order.$error" class="invalid-feedback">
          <span v-if="!$v.form.order.required">This value is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label>Tags</label>
        <multiselect v-model="form.tags" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="hashtagId" :options="hashtags" :multiple="true" :taggable="true" :loading="isLoadingTag"  @tag="addTag" @search-change="fetchTags"></multiselect>
      </div>

      <div class="form-group">
        <label>Availablity</label>
        <multiselect :class="{ 'is-invalid': isAvailabilityNotSelected }" v-model="form.availability" placeholder="Select multiple flatform" :options="availabilities" :multiple="true">
          <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
          <template slot="tag" slot-scope="{ option }">
            <span class="multiselect__tag">
              <span>{{ option | capitalize }}</span> 
              <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"  @click="removeAvailibility(option)"></i>
            </span>
          </template>
        </multiselect>
        <div v-if="isAvailabilityNotSelected" class="invalid-feedback">
          <span>This value is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label>Categories</label>
        <multiselect :class="{ 'is-invalid': isCategoriesNotSelected }" v-model="form.categories" placeholder="Select multiple category" track-by="categoryId"  :options="categories" :multiple="true">
          <template slot="option" slot-scope="{ option }"><strong>{{ option.name | capitalize }}</strong></template>
          <template slot="tag" slot-scope="{ option }">
            <span class="multiselect__tag">
              <span>{{ option.name | capitalize }}</span> 
              <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"  @click="removeCategory(option)"></i>
            </span>
          </template>
        </multiselect>
        <div v-if="isCategoriesNotSelected" class="invalid-feedback">
          <span>This value is required.</span>
        </div>
      </div>

      <div class="form-group">
        <label>Status</label>
        <multiselect :class="{ 'is-invalid': isStatusNotSelected }" v-model="form.status" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="options" :searchable="false" :allow-empty="false">
          <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
        </multiselect>
        <div v-if="isStatusNotSelected" class="invalid-feedback">
          <span>This value is required.</span>
        </div>
      </div>

      <div class="form-group mb-0">
        <div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>